import React from 'react';
import './WorkingGroups.css'
import {Container, Row, Col, Table} from 'react-bootstrap';

const onsite = ['A-10', 'Aeromedical Evacuation / Surgeon General', 'Air Base Operations', 'Air Operations Center', 'Battle Control Center', 'C-5', 'C-17', 'C-130', 'Contingency Response Group', 'Control and Reporting Center', 'Cyber', 'Cyber Enabled Air Superiority (MAWG)', 'Explosive Ordnance Disposal' ];
const onsite2 = ['F-15', 'F-16', 'F-22', 'F-35', 'Guardian Angel', 'HC-130', 'HH-60G/W', 'Intel', 'KC-46', 'KC-135', 'Maintenance', 'MQ-9', 'Security Forces', 'Space', 'Special Tactics', 'Tactical Air Control Party / Air Support Operations Squadron'];
const offsite = ['ABMS FoS', 'B-52', 'C-32B', 'C-40', 'LC-130', 'MC-130', 'Ranges'];
const mawg = ['Air Superiority', 'Isolated Personnel', 'Personnel Recovery','Rapid Global Mobility'];
const chairs = [
{org: <i>The list of the 2025 Working Group Chairs will be posted soon</i>},
  /*{org: 'A-10', chair: 'Maj Richard Noble'},
  {org: 'ABMS FoS', chair: 'Maj Dan Routier'},
  {org: 'Aeromedical Evacuation', chair: 'MSgt Rachel Edwards'},
  {org: 'Air Base Operations', chair: 'Capt Brian Rafael'},
  {org: 'Air Operations Center', chair: 'Maj Daniel Wallace'},
  {org: 'Air Superiority MAWG', chair: 'Maj Tanner Lee'},
  {org: 'B-52', chair: 'Maj Brent Drabek'},
  {org: 'Battle Control Center', chair: 'Maj Greg Firestone'},
  {org: 'C-5', chair: 'Maj Jonathan Jordan'},
  {org: 'C-17', chair: 'Maj Brandon Chang'},
  {org: 'C-32B', chair: 'Maj Jordan Novotny'},
  {org: 'C-40', chair: 'Maj Grant Hadley'},
  {org: 'C-130', chair: 'Maj Jamie Boudreaux'},
  {org: 'Contingency Response Group', chair: 'CMSgt Jeremiah Wickenhauser'},
  {org: 'Control and Reporting Center', chair: 'Maj William Rief'},
  {org: 'Cyber (Defensive Cyber Ops)', chair: 'Capt Quenton Rexroth'},
  {org: 'Cyber (Offensive Cyber Ops)', chair: 'Capt Nick Gildenmeister'},
  {org: 'Cyber (DoD Information Network Ops)', chair: 'SSgt Armaan Pathak'},
  {org: 'Cyber Enabled Air Superiority MAWG', chair: 'Lt Col Matthew Weiner'},
  {org: 'Explosive Ordnance Disposal', chair: 'MSgt Aaron Weber'},
  {org: 'F-15', chair: 'Maj Calvin Conner'},
  {org: 'F-16', chair: 'Lt Col Jason Bright'},
  {org: 'F-22', chair: 'Maj Sean Schiess'},
  {org: 'F-35', chair: 'Maj Martin Smith'},
  {org: 'Guardian Angel', chair: 'Capt Brian Alfano'},
  {org: 'HC-130', chair: 'Maj George Geiges'},
  {org: 'HH-60G/W', chair: 'Maj Brendan Sullivan'},
  {org: 'Intel', chair: 'Maj James Muhs'},
  {org: 'Isolated Personnel MAWG', chair: 'MSgt Roy Wollgast'},
  {org: 'KC-46', chair: 'Lt Col Daniel Mecham'},
  {org: 'KC-135', chair: 'Capt Patrick Retzer'},
  {org: 'LC-130', chair: 'Maj Eric Chan'},
  {org: 'Maintenance', chair: 'Capt Richard Lambert'},
  {org: 'MC-130', chair: 'Lt Col Chad Gontkovic'},
  {org: 'MQ-9', chair: 'Maj Joshua Rudolf'},
  {org: 'Personnel Recovery MAWG', chair: 'Capt Mark Joseloff'},
  {org: 'Ranges', chair: 'Lt Col Nicholas Smith'},
  {org: 'Rapid Global Mobility MAWG', chair: 'Lt Col Clinton Albaugh'},
  {org: 'Security Forces', chair: 'Lt Col Torrence Barbour'},
  {org: 'Space', chair: 'Capt Ethan Labonte'},
  {org: 'Special Tactics', chair: 'MSgt Eric Nelson'},
  {org: 'Surgeon General', chair: 'CMSgt Thomas Wastart'},
  {org: 'Tactical Air Control Party / Air Support Operations Squadron', chair: '2d Lt Michael Maines'},*/
  ]

export default class WorkingGroups extends React.Component{
  render() {
  return (
    <Container>
      <Row>
        <Col lg={3} className='columns'>
          <h1 className='section-title'> Meeting During ARC WEPTAC </h1>
        </Col>
        <Col lg={9}>
        <div class="row">
             <h2 className='subtitle-text'> On-Site (in Tucson) </h2>
             <div class="col">
             <p>{onsite.map((name) => <li className="wg-list">{name}</li>)}</p></div>
             <div class="col">
             <p>{onsite2.map((name) => <li className="wg-list">{name}</li>)}</p></div>
        </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg={3} className='columns'>
          <h1 className='section-title'> Meeting Prior to ARC WEPTAC </h1>
        </Col>
        <Col lg={4}>
          <h2 className='subtitle-text'> Off-Site (at Home Base) </h2>
          <p>{offsite.map((name) => <li className="wg-list">{name}</li>)}</p>
        </Col>
        <Col lg={5}>
          <h2 className='subtitle-text'> MAWG (Offsite and/or Virtual) </h2>
          <p>{mawg.map((name) => <li className="wg-list">{name}</li>)}</p>
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col lg={3} className='columns'>
          <h1 className='section-title'> Chairs </h1>
        </Col>
        <Col lg={9} className='columns'>
          <Table>
            <thead>
              <tr>
              <th><font color='#2e78a6'>MWS/MAWG</font> </th>
              <th><font color='#2e78a6'>Chair </font></th>
              </tr>
            </thead>
            <tbody>
              {chairs.map(({org, chair}) =>
                <tr>
                  <td>{org}</td>
                  <td>{chair}</td>
                </tr>)
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
}

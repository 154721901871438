import React from 'react';
import './Resources.css';
import { Container, Row, Col } from 'react-bootstrap';

export default class Resources extends React.Component {
    render() {
    return (
        <Container className='contact-info'>
            <Row className='justify-content-center'>
                <Col className='resources' lg={12}>
                <Col lg={12}><h1 className='titles mb-4'> Resources </h1></Col>
                <h3 className='subtitles m-0 p-0 pb-2 pt-2'>Coming Soon</h3>

                {/*<h3 className='subtitles m-0 p-0 pb-2 pt-2'>Hotel Room Blocks:</h3>
                <li><a id="roomBlocks" href="/docs/Room Block_2024_FOR WEBSITE.xlsx" target="_blank"> Click here to view the block of hotel rooms available at government per diem </a></li><br/>
                <h3 className='subtitles m-0 p-0 pb-2 pt-2'>Onsite Working Group Locations:</h3>
                <li><a id="roomBlocks" href="/docs/WEPTAC Maps 2024.pdf" target="_blank"> Click here to view the map of working group locations </a></li><br/>
                <h3 className='subtitles m-0 p-0 pb-2 pt-2'>Spectrum Warfare Day Agenda:</h3>
                <li><a id="roomBlocks" href="/docs/SWD Agenda 2024.pdf" target="_blank"> Click here to view the 2024 SWD Agenda </a></li>*/}

                </Col>
            </Row>
        </Container>
    );
}}

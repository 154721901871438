import React from 'react';
import './Notice.css';

function Notice() {
  return (
    <div>
      <p className='notice-text'>Tucson, AZ <br/> <font color='red'>
       2025 DATES & INFORMATION COMING SOON
      {/*<br/><font color='red'><i> 20-24 October 2025 <br/></i></font>*/}
      </font></p>
    </div>
  );
}

export default Notice;

import React from 'react';
import './Contact.css';
import {Container, Row, Col} from 'react-bootstrap';

export default class Contact extends React.Component{
  render() {
  return (
    <Container className='contact-info'>
      <Row className='justify-content-center'>
        <Col lg={12}><h1 className='titles'> Contact Information </h1></Col>
      </Row>
      <br/>
      <Row className='justify-content-center'>
        <Col lg={6}>
          <p> WEPTAC Chair, Maj Bennett Merriman </p>
          <a href="mailto:bennett.merriman.1@us.af.mil" className='contact-title text-decoration-none'> bennett.merriman.1@us.af.mil </a>
          <p className='contact-title mt-3'> (520) 240-1894 </p>
        </Col>
        <Col lg={6}>
          <p> WEPTAC Coordinator, Mrs. Kim Casey </p>
          <a href="mailto:kimberly.casey.2.ctr@us.af.mil" className='contact-title text-decoration-none'> kimberly.casey.2.ctr@us.af.mil </a>
          <p className='contact-title mt-3'> (520) 268-5657 </p>
        </Col>
      </Row>
    </Container>
  );
}
}

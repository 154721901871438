import React from 'react';
import '../../App.css';
import Notice from '../Notice';
import MainBody from '../MainBody';
import Footer from '../Footer';

function Home() {
  return (
    <>
      <Notice />
      <MainBody />
      <Footer />
    </>
  );
}

export default Home;

import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const modBooksLink = () => window.open("https://www.ang.af.mil/About/Strategic-Priorities", '_blank').focus();

  return (
    <>
      <nav className='navbar justify-content-center position-sticky'>
        <div className='navbar-container'>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link
                to='/'
                className='nav-links'
                onClick={handleClick}
              >
                Home
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/gen-info'
                className='nav-links'
                onClick={handleClick}
              >
                General Information
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/working-groups'
                className='nav-links'
                onClick={handleClick}
              >
                Working Groups
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to="/mod-books"
                target="_blank"
                className='nav-links'
                onClick={modBooksLink}
              >
                Modernization Books
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/industry'
                className='nav-links'
                onClick={handleClick}
              >
                Industry
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/contact'
                className='nav-links'
                onClick={handleClick}
              >
                Contact
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/resources'
                className='nav-links'
                onClick={handleClick}
              >
                Resources
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;

import React from 'react';
import Navbar from '../src/components/Navbar';
import './App.css';
import Home from './components/pages/Home.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GenInfo from './components/pages/GenInfo/GenInfo';
import WorkingGroups from './components/pages/WorkingGroups/WorkingGroups';
import Industry from './components/pages/Industry/Industry';
import Contact from './components/pages/Contact/Contact';
import Resources from './components/pages/Resources/Resources';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/gen-info' element={<GenInfo />} />
          <Route path='/working-groups' element={<WorkingGroups  />} />
          <Route path='/industry' element={<Industry />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/resources' element={<Resources />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import React from 'react';
import '../App.css';
import { Button } from './Button';
import './MainBody.css';
import largePlane from '../large_plane.svg';

function MainBody() {

  const rankFiveBelow = () => window.open("https://usaf.dps.mil/sites/ARC-WEPTAC/_layouts/15/listforms.aspx?cid=ZWY2ZWVhMGMtYTk1OS00NDZhLTg0MmYtMmQzNTM0Y2YwNmFj&nav=MmMxYWMwOTEtYjk0MC00NTI2LTk5ZTgtN2MyYWUxMzExOTI4", '_blank').focus();
  const rankSix = () => window.open("https://usaf.dps.mil/sites/ARC-WEPTAC/_layouts/15/listforms.aspx?cid=ZGU3YTU1NzItMzc1OC00MDE2LTgzZjMtYmNjYWUzYzk1NzRi&nav=OGY3Njc1ZDItZjZjMS00MDczLTlmNDAtY2IxMmVkMDUyMzhm", '_blank').focus();
//  const golf = () => window.open("http://arcweptac.com/docs/2024 AATC WEPTAC Golf Tournament.pdf", '_blank').focus();

  return (
    <div className='main-container'>
      <img src='/images/weptac-bg.png' alt="" />
      <img className="bigPlane slide-in" src={largePlane} alt='big-plane-slide-in'></img>



    </div>
  );
}

export default MainBody;


// INSERT THIS CODE ABOVE LAST DIV> TO BRING REGISTRATION BUTTONS BACK
//     <div className='main-btns justify-content-center'>
//           <Button className='btn' buttonStyle='btn--outline' buttonSize='btn--large' onClick={rankSix}>  <font color='red'></font> Rank O-6 Request to Attend Submission Page<br/><font color='red'><i> CLOSES 4 OCT</i></font> </Button>
//           </div>

//      <div className='main-btns justify-content-center'>
//            <Button className='btn' buttonStyle='btn--outline' buttonSize='btn--large' onClick={rankFiveBelow}> Ranks O-5 and Below, CIVs & Gov CTRs Request to Attend Submission Page <br/><font color='red'><i> CLOSES 4 OCT</i></font> </Button>
//            </div>

//FOR GOLF OR EXTRA BUTTON
//<div className='main-btns justify-content-center'>
//          <Button className='btn' buttonStyle='btn--outline' buttonSize='btn--large' onClick={golf}> 2024 ARC WEPTAC Golf Tournament! <br/><font color='red'><i> CLOSES 17 OCT <br/></i></font> </Button>
//      </div>

//const rankFiveBelow = () => window.open("https://usaf.dps.mil/sites/ARC-WEPTAC/_layouts/15/listforms.aspx?cid=ZWY2ZWVhMGMtYTk1OS00NDZhLTg0MmYtMmQzNTM0Y2YwNmFj&nav=MmMxYWMwOTEtYjk0MC00NTI2LTk5ZTgtN2MyYWUxMzExOTI4", '_blank').focus();
//  const rankSix = () => window.open("https://usaf.dps.mil/sites/ARC-WEPTAC/_layouts/15/listforms.aspx?cid=ZGU3YTU1NzItMzc1OC00MDE2LTgzZjMtYmNjYWUzYzk1NzRi&nav=OGY3Njc1ZDItZjZjMS00MDczLTlmNDAtY2IxMmVkMDUyMzhm", '_blank').focus();
//  const golf = () => window.open("http://arcweptac.com/docs/2024 AATC WEPTAC Golf Tournament.pdf", '_blank').focus();